import { AxiosInstance } from 'axios'

export interface AnalyticsEvent {
  event: string
  properties?: object
  context?: object
}

export default class Analytics {
  axios: AxiosInstance

  constructor (axios: AxiosInstance) {
    this.axios = axios
  }

  track (analyticsEvent: AnalyticsEvent) {
    const params = new URLSearchParams(window.location.search)

    const analyticsPayload: AnalyticsEvent = {
      ...analyticsEvent,
      properties: {
        ...analyticsEvent.properties,
        pageUrl: window.location.href,
        pagePath: window.location.pathname
      },
      context: {
        utm_source: params.get('utm_source'),
        utm_medium: params.get('utm_medium'),
        utm_campaign: params.get('utm_campaign'),
        utm_term: params.get('utm_term'),
        utm_content: params.get('utm_content')
      }
    }

    return this.axios.post('/api/v1/segvents', analyticsPayload).catch(() => {})
  }
}
